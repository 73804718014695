import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import moment, { Moment } from "moment";
import { useField } from "formik";
import { useShop } from "../../Shop.context";
import { deliveryLocations } from "../../../../../src/shared/utils/deliveryLocation.utils";
import { asDdMmYyyy, asIso8601, momentFromDdMmYyyy, upcomingWeekdaysForNextNumberOfDays } from "../../../../../src/shared/utils/date.utils";
import classNames from "classnames";
import {remove} from "lodash";

interface DeliveryDateOptionsProps {

}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  padding: 4px;

  &.p-invalid {
    border: 1px solid var(--invalid);
  }
`;

const DeliveryDate = styled.div`
  border: 1px solid var(--green);
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover, &.selected {
    background-color: var(--green-40);
  }

  &:not(:last-child) {
    margin-right: 32px;
  }

  .day {
    text-transform: uppercase;
    font-weight: bold;
  }

  .hours {
    font-size: 90%;
  }
`;


const DeliveryDateOptions = (props: DeliveryDateOptionsProps) => {
  const [possibleDeliveryDates, setPossibleDeliveryDates] = useState<Moment[]>([]);
  let field = useField('deliveryDate');
  const meta = field[1];
  const helper = field[2];

  const isFormFieldValid = () => !!((meta.touched) && meta.error);

  const {
    shopDetails: {
      deliveryLocation,
      deliveryDate,
    },
    setPersonalField,
  } = useShop();

  useEffect(() => {
    helper.setValue(deliveryDate);
  }, [deliveryDate]);

  useEffect(() => {
    let selectedDeliveryLocation = deliveryLocations.find(d => d.value === deliveryLocation)
    if (selectedDeliveryLocation) {
      let deliveryDates = upcomingWeekdaysForNextNumberOfDays(moment(), selectedDeliveryLocation.shopDays, 0, 8);

      //remove(deliveryDates, (deliveryDate) => deliveryDate.isAfter(momentFromDdMmYyyy("20-12-2024"), 'day'));

      // if (deliveryDates.length > 0) {
      //   setPersonalField('deliveryDate', first(deliveryDates));
      // }

      setPossibleDeliveryDates(deliveryDates);
    } else {
      setPossibleDeliveryDates([]);
    }
  }, [deliveryLocation]);

  let selectedDeliveryLocation = deliveryLocations.find(d => d.value === deliveryLocation);
  return <>
    <h3 className={classNames({'p-invalid': (meta.touched && meta.error)})}>Afhaalmoment</h3>
    <div>
      {isFormFieldValid() && <small className={'p-error'}>{meta.error}</small>}
      <Container className={classNames({'p-invalid': isFormFieldValid()})}>
        {
          possibleDeliveryDates.map(possibleDeliveryDate =>
            <DeliveryDate
              onClick={() => {
                let value = asIso8601(possibleDeliveryDate);
                setPersonalField('deliveryDate', value);
                helper.setValue(value);
              }}
              className={classNames({selected: possibleDeliveryDate.isSame(deliveryDate, 'd')})}
            >
              <div className={'day'}>{possibleDeliveryDate.format('dddd')}</div>
              <div className="date">{asDdMmYyyy(possibleDeliveryDate)}</div>
              <div className="hours">{selectedDeliveryLocation?.shopHours}</div>
            </DeliveryDate>)
        }
      </Container>
    </div>
  </>;
};

export default DeliveryDateOptions;
